<template>
  <div id="app">
    <!-- 路由占位符 -->
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>
#app {
  height: 100%;
}
</style>
