import axios from 'axios'

let mybaseURL
if (process.env.NODE_ENV === 'production') {
  // console.log(process.env.NODE_ENV)
  // mybaseURL = 'http://test2.kjtei.com:52025/manage'
  var host = window.location.host
  mybaseURL = window.location.protocol + '//' + host + '/datas/api/manage'
} else {
  mybaseURL = '/app'
}
/**
 * 封装 axios 请求模块
 */
const service = axios.create({
  baseURL: mybaseURL, // '/app', // 基础路径\
  timeout: 50000
})
service.interceptors.request.use()
service.interceptors.response.use()
export default service
