export function getTRSToken () {
  // return document.cookie.split('=')[1]
  const cookies = document.cookie.split(';')
  if (cookies.length > 1) {
    for (let i = 0; i < cookies.length; i++) {
      if (cookies[i].includes('TOKEN')) {
        return cookies[i].split('=')[1]
      }
    }
  } else {
    return document.cookie.split('=')[1]
  }
}
