<template>
    <el-container class="layout_container">
        <!-- 头部区域 -->
<!--        <el-header style=" background-image: linear-gradient(to right, #7CA1F7 , #7CA1F7);">-->
<!--            <span>TDCMS | 科技后台管理系统</span>-->
<!--            <div>11</div>-->
<!--        </el-header>-->
      <!--        左侧-->
      <el-container>
          <el-aside width="200px;">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-vertical-demo"
              @open="handleOpen"
              @close="handleClose"
              background-color="#ECF1F4"
              text-color="black"
              active-text-color="blue"
              :router="true"
              :collapse="isCollapse"
              :collapse-transition='true'
            >
              <el-menu-item style="font-weight: bold">APP管理</el-menu-item>
              <el-menu-item index="/Startpagemanagement" v-show="data[0]&&data[0].selected==1">
                <template>
                  <span>启动页管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/Guidepagemanagement" v-show="data[1]&&data[1].selected==1">
                <template>
                  <span>引导页管理</span>
                </template>
              </el-menu-item>
<!--              <el-menu-item index="/Navigationiconmanagement" v-show="data[2]&&data[2].selected==1">
                <template>
                  <span>导航栏图标管理</span>
                </template>
              </el-menu-item>-->
              <el-menu-item index="/Liveactivitymanagement" v-show="data[3]&&data[3].selected==1">
                <template>
                  <span>直播活动管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/Votingactivitymanagement" v-show="data[4]&&data[4].selected==1">
                <template>
                  <span>投票活动管理</span>
                </template>
              </el-menu-item>
              <!-- 点赞管理 -->
              <el-menu-item index="/Likemanagement" v-show="data[5]&&data[5].selected==1">
                <template>
                  <span>点赞管理</span>
                </template>
              </el-menu-item>
              <!-- 评论管理 -->
              <el-menu-item index="/Commentmanagement" v-show="data[6]&&data[6].selected==1">
                <template>
                  <span>评论管理</span>
                </template>
              </el-menu-item>
              <!-- 收藏管理 -->
              <el-menu-item index="/Collectionmanagement" v-show="data[7]&&data[7].selected==1">
                <template>
                  <span>收藏管理</span>
                </template>
              </el-menu-item>
              <!-- 订阅科技号管理 -->
              <el-menu-item index="/Subscriptiontechnologymanagement" v-show="data[8]&&data[8].selected==1">
                <template>
                  <span>订阅科技号管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/Appversionmanagement" v-show="data[9]&&data[9].selected==1">
                <template>
                  <span>App版本管理</span>
                </template>
              </el-menu-item>
              <el-menu-item index="/Feedbackmanagement" v-show="data[10]&&data[10].selected==1">
                <template>
                  <span>意见反馈管理</span>
                </template>
              </el-menu-item>
            </el-menu>
          </el-aside>
        <!-- 路由占位符 -->
          <el-main>
            <router-view></router-view>
          </el-main>
      </el-container>
    </el-container>
</template>

<script>
// import { getAccessToken } from '@/utils/storage'
import { getUserInfo } from '@/api/userinfo/userinfo'
import { getPermission } from '@/api/userinfo/userinto'
import { mapActions, mapState } from 'vuex'
import qs from 'qs'
import { getTRSToken } from '@/utils/store'
export default {
  name: 'LayOut',
  data () {
    return {
      // 左侧菜单数据
      menuList: [],
      // 折叠
      isCollapse: false,
      activeIndex: this.$route.path,
      // token: '',
      data: []
    }
  },
  created () {
    // 在初始化时取出以保存的页面路径
    this.$router.path = '/Startpagemanagement'
    this.getCookie()
    // this.getPermis() // 测试
    // this.getUserInfoMethods()
  },
  computed: {
    ...mapState(['userName', 'matdata'])
  },
  methods: {
    // vuex
    ...mapActions(['getUserName', 'getuserParams']),
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    // 获取cookie值
    getCookie () {
      // console.log(getTRSToken())
      this.getUserInfoMethods(getTRSToken())
      this.getPermis(getTRSToken())
    },
    // 用户信息
    getUserInfoMethods (token) {
      const param = {
        tokenJwt: token
      }
      getUserInfo(qs.stringify(param)).then((res) => {
        this.getUserName(res.data.data.userName)
      })
    },
    // 权限
    getPermis (token) {
      getPermission(token).then((res) => {
        // console.log(res.data.data.sons[2].sons[3].sons.name)
        this.data = res.data.data.sons[2].sons[3].sons
        this.getuserParams(res.data.data.sons[2].sons[3].sons)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.layout_container {
    height: 100%;
}
.el-header {
    background-color: rgb(38, 130, 235);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.el-aside {
    background-color: #ECF1F4;
}
.el-main {
    background-color: #fff;
    padding: 0;
}
.el-menu-item.is-active {
  background-color: #fff !important;//你要修改的颜色
}
.el-aside::-webkit-scrollbar {   //.page可以更换为任意元素
  display: none;
}
.el-main {
  display: block;
  flex: 1;
  flex-basis: auto;
  overflow: auto;
  box-sizing: border-box;
  margin-top: 20px;
}
.el-menu-item{
  border-bottom: 1px dashed #a1aeb54a;
}
</style>
