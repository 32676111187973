import axios from 'axios'
import { getTRSToken } from '@/utils/store'

let mybaseURL
if (process.env.NODE_ENV === 'production') {
  // mybaseURL = 'http://appmagtest.cibst.cn/management'
  // mybaseURL = 'http://test2.kjtei.com:52025/management/management'
  var host = window.location.host
  mybaseURL = window.location.protocol + '//' + host + '/management/management'
} else {
  mybaseURL = '/api'
}
/**
 * 封装 axios 请求模块
 */
const service = axios.create({
  baseURL: mybaseURL, // '/api', // 基础路径\
  timeout: 50000
})
service.interceptors.request.use()
service.interceptors.response.use()
// 添加请求拦截器，在请求头中加token
service.interceptors.request.use(
  config => {
    config.headers['TRSDH-Access-Token'] = getTRSToken()
    return config
  },
  error => {
    return Promise.reject(error)
  })
export default service
