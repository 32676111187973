import request from '@/utils/request'
/*
 * 获取用户信息
 *
 */
export function getUserInfo (token) {
  return request({
    url: '/info',
    data: token,
    method: 'post',
    headers: { 'TRSDH-Access-Token': token }
  })
}

// /*
//  * 获取用户权限
//  *
//  */
// export function getPermission (token) {
//   return request({
//     url: '/app/user/permission',
//     data: token,
//     method: 'post'
//   })
// }
