import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userName: '',
    matdata: [],
    objedata: {},
    objSubdata: {}
  },
  getters: {
  },
  mutations: {
    changeUserName (state, payload) {
      state.userName = payload
    },
    pramsdata (state, payload) {
      state.matdata = payload
    },
    objdelist (state, payload) {
      state.objedata = payload
    },
    objdeSubList (state, payload) {
      state.objSubdata = payload
    }
  },
  actions: {
    getUserName (context, payload) {
      context.commit('changeUserName', payload)
    },
    getuserParams (context, payload) {
      context.commit('pramsdata', payload)
    },
    getuserParamsobj (context, payload) {
      context.commit('objdelist', payload)
    },
    // 订阅详情
    getSubUserParamsobj (context, payload) {
      context.commit('objdeSubList', payload)
    }
  },
  modules: {
  }
})
