import request from '@/utils/requests'

/*
 * 获取用户权限
 *
 */
export function getPermission (token) {
  return request({
    url: '/user/permission',
    headers: { 'TRSDH-Access-Token': token },
    method: 'get'
  })
}
