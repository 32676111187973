import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LayOut',
    component: Layout,
    redirect: '/Startpagemanagement',
    children: [
      {
        path: 'Startpagemanagement',
        // component: StartPageManagement
        component: () => import(/* webpackChunkName: "about" */ '@/components/Startpagemanagement/StartPageManagement.vue')
      },
      {
        path: 'Guidepagemanagement',
        // component: GuidePageManagement
        component: () => import(/* webpackChunkName: "about" */ '@/components/Guidepagemanagement/GuidePageManagement.vue')
      },
      {
        path: 'Navigationiconmanagement',
        // component: NavigationIconManagement
        component: () => import(/* webpackChunkName: "about" */ '@/components/Navigationiconmanagement/NavigationIconManagement.vue')
      },
      {
        path: 'Liveactivitymanagement',
        // component: LiveActivityManagement
        component: () => import(/* webpackChunkName: "about" */ '@/components/Liveactivitymanagement/LiveActivityManagement.vue')
      },
      {
        path: 'Appversionmanagement',
        // component: AppVersionManagement
        component: () => import(/* webpackChunkName: "about" */ '@/components/Appversionmanagement/AppVersionManagement.vue')
      },
      {
        path: 'Feedbackmanagement',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Feedbackmanagement/FeedbackManagement.vue')
      },
      {
        path: 'Votingactivitymanagement',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Votingactivitymanagement/VotingActivityManagement.vue')
      },
      {
        path: '/child/Launchpagedetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Appversionmanagement/child/LaunchPageDetails.vue')
      },
      {
        path: '/child/LiveActivitydetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Liveactivitymanagement/child/LiveActivityDetails')
      },
      {
        path: '/child/Feedbackdetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Feedbackmanagement/child/FeebackDetails')
      },
      {
        path: '/child/VotingAvitydetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Votingactivitymanagement/child/VotingActivityDetails')
      },
      {
        path: '/child/Commentdetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Commentmanagement/child/CommentDetails')
      },
      {
        path: '/child/Likedetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Likemanagement/child/LikeDetails')
      },
      {
        path: '/child/Collectiondetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Collectionmanagement/child/CollectionDetails')
      },
      {
        // path: '/child/SubscriptionTechnologydetails',
        path: '/child/SubscriptionTechnologydetails',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Subscriptiontechnologymanagement/child/SubscriptiontechnologyDetails')
      },
      {
        path: '/child/Startpagedetailsdetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Startpagemanagement/child/StartPageDetails.vue')
      },
      {
        path: '/child/NavigationIcondetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Navigationiconmanagement/child/NavigationIcoDetails')
      },
      {
        path: '/child/GuidePagedetails/:id',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Guidepagemanagement/child/GuidePageDetails')
      },
      {
        path: '/Launchpageedit',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Appversionmanagement/child/LaunchPageEdit.vue')
      },
      {
        path: '/NewGuidePage',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Guidepagemanagement/child/NewGuidePage')
      },
      {
        path: '/Liveactivityedit',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Liveactivitymanagement/child/LiveActivityEdit')
      },
      {
        path: '/Guidepageedit',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Guidepagemanagement/child/GuidePageEdit')
      },
      {
        path: '/Votingactivityedit',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Votingactivitymanagement/child/VotingActivityEdit')
      },
      {
        path: '/NavigationIconedit',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Navigationiconmanagement/child/NavigationIconEdit')
      },
      {
        path: '/Startpageedit',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Startpagemanagement/child/StartPageEdit')
      },
      {
        path: 'Likemanagement',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Likemanagement/LikeManagement.vue')
      },
      {
        path: 'Commentmanagement',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Commentmanagement/CommentManagement.vue')
      },
      {
        path: 'Collectionmanagement',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Collectionmanagement/CollectionManagement.vue')
      },
      {
        path: 'Subscriptiontechnologymanagement',
        // component: FeedbackManagement,
        component: () => import(/* webpackChunkName: "about" */ '@/components/Subscriptiontechnologymanagement/SubscriptionTechnologyManagement.vue')
      }
    ]
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

router.afterEach((to, from) => {
  document.title = '科技日报APP后台管理系统' // to.meta.title // 在全局后置守卫中获取路由元信息设置title
})

export default router
